import { graphql, PageProps } from "gatsby";
import React from "react";
import HeroImage from "../components/HeroImage";
import NewsFooter from "../components/NewsFooter";
import { NewsItem } from "../components/NewsItem";
import Page from "../components/Page";
import SEO from "../components/SEO";
import { innerHtmlFromPrismicRaw } from "../util";

export const query = graphql`
  {
    prismicNewsItems {
      id
      data {
        body {
          ... on PrismicNewsItemsBodyText {
            id
            primary {
              text {
                raw
              }
            }
          }
        }
      }
    }
    prismicHomepageImage {
      data {
        credit_link
        credit_name
        image_title {
          raw
        }
        image {
          alt
          copyright
          url
          thumbnails
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage: React.FC<PageProps<any>> = ({ data }) => {
  const homePageImage =
    data.prismicHomepageImage.data.image.localFile.childImageSharp.fluid;
  const imageCreditText = data.prismicHomepageImage.data.credit_name;
  const imageCreditLink = data.prismicHomepageImage.data.credit_link;
  const newsItems = (data.prismicNewsItems.data
    .body as any[]).map((it, idx) => (
    <NewsItem
      key={`news-item-${idx}`}
      innerHTML={innerHtmlFromPrismicRaw(it.primary.text.raw)}
    />
  ));
  return (
    <>
      <SEO title="Home" />
      <Page fullWidth={true} noFooter={true}>
        <HeroImage
          title="Arthur Bruce - Baritone"
          credit={{ link: imageCreditLink, title: imageCreditText }}
          data={homePageImage}
        />
        <NewsFooter items={newsItems} />
      </Page>
    </>
  );
};

export default IndexPage;
