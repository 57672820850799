import styled from "@emotion/styled";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import { Image as ImageHeroProps } from "../types";
import { NAV_HEIGHT } from "./Header";
import iosHeight from "ios-inner-height";
import { useMediaQuery } from "@chakra-ui/media-query";

const StyledHeroImageWrapper = styled.div<{
  height: string;
  imagePositionY: string;
}>`
  margin: 0;
  position: fixed;
  z-index: 0;
  height: calc(${(props) => props.height} - ${NAV_HEIGHT});
  width: 100vw;

  & img {
    // !important lets us override Img css
    object-position: center ${(props) => props.imagePositionY} !important;
  }
`;

const HeroImage: React.FC<ImageHeroProps> = (props) => {
  const { data } = props;

  let altText = `${props.title}`;
  if (props.credit) {
    altText = `${altText} - by ${props.credit.title}`;
  }

  const [needsToLowerImage] = useMediaQuery("(min-width: 1200px)");
  const imagePositionY = needsToLowerImage ? "40%" : "center";

  return (
    <StyledHeroImageWrapper
      height={`${iosHeight()}px`}
      imagePositionY={imagePositionY}
    >
      <Img
        fluid={data as FluidObject}
        // https://stackoverflow.com/a/49962664
        style={{
          width: "100vw",
          height: `calc(100vh - ${NAV_HEIGHT})`
        }}
        loading={"eager"}
        alt={altText}
      />
    </StyledHeroImageWrapper>
  );
};

export default HeroImage;
