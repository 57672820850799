import { useBreakpointValue } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { animated, useSprings } from "react-spring";
import theme from "../theme";

export interface NewsFooterProps {
  items: React.ReactNodeArray;
  slideDuration?: number;
  // Stick at the first item
  stopAnimation?: boolean;
}

const StyledFooterWrapper = styled.div<{ footerFontSize: string }>`
  position: fixed;
  bottom: 0;
  left: 0;
  // Account for the extra out-padding of the margin
  width: calc(100vw + ${theme.space[4]});

  // Account for the default browser <body> margin
  margin-left: -${theme.space[2]};
  margin-right: -${theme.space[2]};

  footer {
    text-align: center;
    background-color: ${theme.colors.white};
    left: 0;
  }

  footer * {
    margin: 0;
    font-size: ${(props) => props.footerFontSize};
  }
`;

const springProps = (
  chosenIndex: number,
  hiddenValue: number,
  preventAnim: boolean
) => (idx: number) => ({
  bottom: chosenIndex === idx ? "0px" : `-${hiddenValue}px`,
  from: {
    bottom: `-${hiddenValue}px`
  },
  config: { duration: 1250 },
  immediate: preventAnim
});

const NewsFooter: React.FC<NewsFooterProps> = ({
  items,
  slideDuration = 6000,
  stopAnimation
}) => {
  const noAnimation = stopAnimation || !items.length;

  const [itemIndex, setItemIndex] = React.useState(0);

  // The minimum height at which all news items
  // will be hidden off the bottom of the screen
  const [hiddenHeight, setHiddenHeight] = React.useState(300);

  const [animSprings, setAnimSprings] = useSprings(
    items.length,
    springProps(itemIndex, hiddenHeight, noAnimation)
  );

  // On itemIndex change, re-distribute animation props so that
  // the next one appears
  React.useEffect(() => {
    const propFn = springProps(itemIndex, hiddenHeight, noAnimation);
    setAnimSprings(propFn as any);
  }, [itemIndex]);

  const footerFontSize = useBreakpointValue([
    theme.fontSizes.md,
    theme.fontSizes.md,
    theme.fontSizes.xl
  ]);

  React.useEffect(() => {
    let intervalId: number | null = null;
    if (!noAnimation) {
      intervalId = window.setInterval(() => {
        setItemIndex((itemIndex + 1) % items.length);
      }, slideDuration);
    }

    return () => {
      if (intervalId) window.clearInterval(intervalId);
    };
  }, [itemIndex]);

  return (
    <StyledFooterWrapper footerFontSize={footerFontSize || theme.fontSizes.md}>
      {animSprings.map((anim, idx) => (
        <animated.footer
          ref={(instance) => {
            setHiddenHeight((current) =>
              instance ? Math.max(instance.offsetHeight, current) : current
            );
          }}
          key={`news-footer-${idx}`}
          style={{
            padding: "20px",
            position: "fixed",
            width: "100%",
            ...anim
          }}
        >
          {items[idx]}
        </animated.footer>
      ))}
    </StyledFooterWrapper>
  );
};

export default NewsFooter;
