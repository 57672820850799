import styled from "@emotion/styled";
import React from "react";
import theme from "../theme";

export interface NewsItemProps {
  // Can be used to render Prismic RichText content
  innerHTML?: string;
}

const StyledNewsItem = styled.h4`
  font-style: italic;
  font-size: ${theme.fontSizes.md};
`;

export const NewsItem: React.FC<NewsItemProps> = (props) => {
  const { innerHTML, children } = props;

  const innerHTMLProp = innerHTML
    ? { dangerouslySetInnerHTML: { __html: innerHTML } }
    : {};

  return <StyledNewsItem {...innerHTMLProp}>{children}</StyledNewsItem>;
};
